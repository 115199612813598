import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import styles from '../../styles/index.module.css';

const DIALOGS_WIDTH = '400px';

const LABELS = {
  OK: 'OK',
};

const ResultDialog = ({
  isResultDialog,
  setResultVoucherDialog,
  dialogResultMsg,
}) => {
  if (!isResultDialog) return null;

  return (
    <Dialog
      width={ DIALOGS_WIDTH }
      onClick={ () => setResultVoucherDialog(false) }
    >
      <div className={ styles.dialog }>
        <h3>
          { dialogResultMsg }
        </h3>
        <div className={ styles.buttons_row }>
          <Button
            width='300px'
            theme='second'
            onClick={ () => setResultVoucherDialog(false) }
          >
            { LABELS.OK }
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ResultDialog.propTypes = {
  isResultDialog: PropTypes.bool.isRequired,
  setResultVoucherDialog: PropTypes.func.isRequired,
  dialogResultMsg: PropTypes.string.isRequired,
};

export { ResultDialog };

