import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Select, Tooltip } from 'sw-ui';

import Timer from '../../../modules/cartsAndNotes/components/Timer';
import AjaxButton from '../../ajaxButton';
import Input from '../../input';

import { isEmail } from '../../../bi/utils/emailValidator';
import { timerAdder } from '../../../bi/utils/formatDate';
import clipboard from '../../../bi/utils/clipboard';

import COMPONENTS from '../../../bi/constants/components';
import { TRIPBUTTONS } from '../../../bi/constants/trips';
import { PASSWORDGENERATOR_TIME_SESSION } from '../../../bi/constants/clientSession';

import FlatButton from '../../button/flatButton';

import styles from '../index.module.css';

const LABELS = {
  HEADER: 'Сгенерировать код',
  ENTRY: 'Сгенерировать код',
  EMAIL: 'email',
  CLIP: 'Пароль скопирован в буфер обмена',
  EMAIL_RU: 'Введите email клиента',
  TIME_SESSION: 'Время сессии',
  END_SESSION: 'До конца сессии осталось:',
  DISABLED: 'Введите email в требуемом формате',
  ERROR: 'Не удалось найти учетную запись',
  TIME_UP: 'Клиентская сессия завершена',
};

const SECONDS = 'seconds';

const {
  DIALOG: { MIDDLE },
  BUTTON: {
    THEME: { FLAT },
  },
  TOOLTIP: {
    POSITION: {
      BOTTOM,
    },
  },
  SELECT: {
    THEME: {
      BORDER,
    },
  },
} = COMPONENTS;

const { CLOSE } = TRIPBUTTONS;

const PassGeneratorForClientSession = ({ onGeneratePass, updatePassStatus, passStatus, onNotification }) => {
  const [email, setEmail] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [deadLine, setDeadLine] = useState('');
  const [showError, setShowError] = useState(false);
  const [showDialogTimeUp, setShowDialogTimeUp] = useState(false);
  const [newPass, setNewPass] = useState('');

  const selectedTime = 3600;

  const handleClipboardCopy = (value) => {
    clipboard(value);
    onNotification();
  };

  const handleUpdatePassStatus = () => {
    updatePassStatus();
  };

  const entryClientSession = async () => {
    setLoadingButton(true);
    setDeadLine('');
    setShowTimer(false);
    handleUpdatePassStatus();

    const password = await onGeneratePass(email, selectedTime);

    if (password) {
      setNewPass(password);
      handleClipboardCopy(password);

      const strDeadLine = timerAdder(selectedTime, SECONDS);
      setDeadLine(strDeadLine);
      setShowTimer(true);

      setLoadingButton(false);
    }

    if (!password) {
      setShowError(true);
      setLoadingButton(false);
    }
  };

  const handleCloseDialogTimeUp = () => {
    setShowDialogTimeUp(false);
    setShowTimer(false);
  };

  const closeTimer = () => {
    setShowDialogTimeUp(true);
  };

  const closeErrorDialog = () => {
    setShowError(false);
  };

  const renderErrorDialog = () => showError ? (
    <Dialog onClick={ closeErrorDialog } width={ MIDDLE }>
      <div className={ styles.dialog }>
        <p>{LABELS.ERROR}</p>
        <div>
          <Button label={ CLOSE } theme={ FLAT } onClick={ closeErrorDialog } />
        </div>
      </div>
    </Dialog>
  ) : null;

  const renderTimeUpDialog = () => showDialogTimeUp ? (
    <Dialog onClick={ handleCloseDialogTimeUp } width={ MIDDLE }>
      <div className={ styles.dialog }>
        <p>{LABELS.TIME_UP}</p>
        <div>
          <Button label={ CLOSE } theme={ FLAT } onClick={ handleCloseDialogTimeUp } />
        </div>
      </div>
    </Dialog>
  ) : null;

  const renderTimer = () => {
    if (!showTimer || !passStatus) {
      return null;
    }

    return (
      <div className={ styles.timer }>
        {LABELS.END_SESSION}
        <Timer
          deadLineInStrUTC={ deadLine }
          onCloseTimer={ closeTimer }
        />
      </div>
    );
  };

  const renderCopyButton = () => passStatus ? (
    <FlatButton
      className={ `${styles.copy_button}` }
      onClick={ () => handleClipboardCopy(newPass) }
    >
      <i className='material-icons'>content_copy</i>
    </FlatButton>
    ) : null;

  const disabled = !isEmail(email);

  const tooltipHtml = disabled ? (
    <Tooltip position={ BOTTOM }>
      <div className={ styles.tooltip }>{LABELS.DISABLED}</div>
    </Tooltip>
  ) : null;

  return (
    <div className={ styles.content }>
      <div className={ styles.header }>
        { LABELS.HEADER }
      </div>
      <div className={ styles.form }>
        <div className={ `${styles.wrapper_input}` }>
          <Input
            type={ LABELS.EMAIL }
            field={ LABELS.EMAIL }
            placeholder={ LABELS.EMAIL_RU }
            onChange={ event => setEmail(event.target.value) }
            value={ email }
            className={ styles.emptyPadding }
          />
        </div>
        <Select
          label={ LABELS.TIME_SESSION }
          className={ styles.wrapper_block }
          items={ PASSWORDGENERATOR_TIME_SESSION }
          value={ selectedTime }
          theme={ BORDER }
          disabled
        />
        <div className={ `${styles.wrapper_block} sw-tooltip-wrapper` }>
          {tooltipHtml}
          <AjaxButton
            label={ LABELS.ENTRY }
            loading={ loadingButton }
            disabled={ disabled }
            onClick={ () => entryClientSession() }
          />
        </div>
        <div>
          {renderCopyButton()}
        </div>
        {renderTimer()}
        {renderErrorDialog()}
        {renderTimeUpDialog()}
      </div>
    </div>
  );
};

PassGeneratorForClientSession.propTypes = {
  onGeneratePass: PropTypes.func.isRequired,
  onNotification: PropTypes.func.isRequired,
  updatePassStatus: PropTypes.func.isRequired,
  passStatus: PropTypes.bool.isRequired,
};

export { PassGeneratorForClientSession };
