import React from 'react';
import PropTypes from 'prop-types';

const LABELS = {
  GREEN: '#209866',
  RED: '#FF1D1D',
};

const Circle = ({ added }) => {
  const renderColor = () => added ? LABELS.GREEN : LABELS.RED;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <circle cx='12' cy='12' r='8' fill={ renderColor() } />
    </svg>
  );
};

Circle.propTypes = {
  added: PropTypes.bool,
};

Circle.defaultProps = {
  added: true,
};

export default Circle;
