import React from 'react';
import PropTypes from 'prop-types';

import { DraggableItem } from '../../DraggableItem';

import styles from '../styles/index.module.css';

const LABELS = {
  ADD_REQUIRED_FIELD: 'Перенесите обязательную карточку',
};

const ApproverComponents = ({
  droppedItems,
  onRemoveItem,
  onDragStart,
  onDragEnd,
  renderInput,
}) => {
  const renderDraggableItems = () => (
    droppedItems.map(item => (
      <DraggableItem
        key={ item }
        onDragStart={ onDragStart }
        onDragEnd={ onDragEnd }
        isRed
        text={ item }
        isStyle
        onClick={ () => onRemoveItem(item) }
        renderContent={ () => renderInput(item) }
      />
    ))
  );

  return (
    <div className={ styles.list_wrap }>
      { renderDraggableItems() }
      <div className={ styles.requaired_field }>
        <div className={ styles.title }>
          { LABELS.ADD_REQUIRED_FIELD }
        </div>
      </div>
    </div>
  );
};

ApproverComponents.propTypes = {
  droppedItems: PropTypes.array.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  renderInput: PropTypes.func,
};

ApproverComponents.defaultProps = {
  onDragStart: () => {},
  onDragEnd: () => {},
  renderInput: null,
};

export { ApproverComponents };
