import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDiscount } from '../../bi/utils/formatPercent';

import styles from '../../modules/trip/styles/form.module.scss';

const CONTRACTHOTELS = {
  ATTENTION: 'Внимание! С отелем есть прямой контракт, надо оформить бронирование по контракту!',
  COMPARE_AANDA: 'Внимание! Отель есть в АА, надо оформить бронирование в АА!',
  PROVIDER: 'Поставщик',
  DISCOUNT: 'Скидка',
  HASVAT: 'Отель работает с НДС',
  TRAVELLINE: 'travelline',
  CONTRACT: 'contract',
  WITHVAT: 'да',
  WITHOUTVAT: 'нет',
};

class ContractHotel extends Component {
  static propTypes = {
    contract: PropTypes.object,
    connected: PropTypes.object,
    compared: PropTypes.object,
  };

  static defaultProps = {
    connected: null,
    contract: null,
    compared: null,
  };

  renderTitle = () => {
    const {
      contract,
      connected,
    } = this.props;

    if (connected) {
      const { Connected = false } = connected;
      const isContract = contract && Object.keys(contract).length;

      return (isContract || Connected) && (
        <span className={ `error-msg ${styles['error-msg-company']}` }>
          { CONTRACTHOTELS.ATTENTION }
        </span>
      );
    }

    return null;
  }

  renderContractHotel = () => {
    const {
      contract,
    } = this.props;

    if (contract) {
      const { Discount: DiscountContract, HasVAT = false } = contract;

      return Object.keys(contract).length > 0 && (
        <span className={ `error-msg ${styles['error-msg-company']}` }>
          { CONTRACTHOTELS.PROVIDER }: { CONTRACTHOTELS.CONTRACT }<br />
          { CONTRACTHOTELS.DISCOUNT }: { formatDiscount(DiscountContract) }%<br />
          { CONTRACTHOTELS.HASVAT }: { HasVAT ? CONTRACTHOTELS.WITHVAT : CONTRACTHOTELS.WITHOUTVAT }
        </span>
      );
    }

    return null;
  }

  renderConnectedHotel = () => {
    const {
      connected,
    } = this.props;

    if (connected) {
      const { Discount: DiscountConnected, Connected = false, WorksWithVAT = false } = connected;

      return Connected && (
        <span className={ `error-msg ${styles['error-msg-company']}` }>
          { CONTRACTHOTELS.PROVIDER }: { CONTRACTHOTELS.TRAVELLINE }<br />
          { CONTRACTHOTELS.DISCOUNT }: { formatDiscount(DiscountConnected) }%<br />
          { CONTRACTHOTELS.HASVAT }: { WorksWithVAT ? CONTRACTHOTELS.WITHVAT : CONTRACTHOTELS.WITHOUTVAT }
        </span>
      );
    }

    return null;
  }

  renderComparedAanda = () => {
    const { compared } = this.props;

    if (compared) {
      const { IdAanda = 0 } = compared;

      return IdAanda !== 0 && (
        <span className={ `error-msg ${styles['error-msg-company']}` }>
          { CONTRACTHOTELS.COMPARE_AANDA }
        </span>
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        { this.renderTitle() }
        { this.renderComparedAanda() }
        { this.renderContractHotel() }
        { this.renderConnectedHotel() }
      </div>
    );
  }
}

export default ContractHotel;
