import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import CircularLoaders from '../loaders';
import { RefundDialog } from './components/RefundDialog';
import { AprooveDialog } from './components/AprooveDialog';
import { ResultDialog } from './components/ResultDialog';

import styles from './styles/index.module.css';

const CancelVoucherDialog = ({
  isRefundDialog,
  setRefundDialog,
  setAprooveDialog,
  isAprooveDialog,
  isResultDialog,
  setResultVoucherDialog,
  loadingCancelDialog,
  dialogResultMsg,
  amountCancelVoucher,
  setCancelVoucherInputValue,
  cancelVoucherInputValue,
  getRefundVoucher,
  cancelVoucher,
  serviceType,
}) => {
  if (loadingCancelDialog) {
    return (
      <Dialog
        onClick={ () => {} }
      >
        <div className={ styles.dialog }>
          <CircularLoaders wrapClassName={ styles.dialog_loader } />
        </div>
      </Dialog>
    );
  }

  return (
    <div>
      <RefundDialog
        isRefundDialog={ isRefundDialog }
        cancelVoucherInputValue={ cancelVoucherInputValue }
        setRefundDialog={ setRefundDialog }
        setCancelVoucherInputValue={ setCancelVoucherInputValue }
        getRefundVoucher={ getRefundVoucher }
        serviceType={ serviceType }
      />
      <AprooveDialog
        setAprooveDialog={ setAprooveDialog }
        isAprooveDialog={ isAprooveDialog }
        amountCancelVoucher={ amountCancelVoucher }
        cancelVoucher={ cancelVoucher }
        serviceType={ serviceType }
        cancelVoucherInputValue={ cancelVoucherInputValue }
        setCancelVoucherInputValue={ setCancelVoucherInputValue }
      />
      <ResultDialog
        isResultDialog={ isResultDialog }
        setResultVoucherDialog={ setResultVoucherDialog }
        dialogResultMsg={ dialogResultMsg }
      />
    </div>
  );
};

CancelVoucherDialog.propTypes = {
  isRefundDialog: PropTypes.bool.isRequired,
  loadingCancelDialog: PropTypes.bool.isRequired,
  isAprooveDialog: PropTypes.bool.isRequired,
  isResultDialog: PropTypes.bool.isRequired,
  setRefundDialog: PropTypes.func.isRequired,
  setAprooveDialog: PropTypes.func.isRequired,
  setResultVoucherDialog: PropTypes.func.isRequired,
  getRefundVoucher: PropTypes.func.isRequired,
  cancelVoucher: PropTypes.func.isRequired,
  setCancelVoucherInputValue: PropTypes.func.isRequired,
  dialogResultMsg: PropTypes.string.isRequired,
  amountCancelVoucher: PropTypes.number.isRequired,
  cancelVoucherInputValue: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export { CancelVoucherDialog };
