import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../bi/utils/formatDate';

import { FIELDS } from '../../bi/constants/trips';
import { TRAIN_TRANSIT_DOCUMENT_TYPE } from '../../bi/constants/train';

import styles from './employee.module.scss';

const KEYCODE = {
  TAB: 9,
  ENTER: 13,
  UP: 38,
  DOWN: 40,
};

class EmployeeSuggest extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
    ]),
    selected: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    valid: PropTypes.string,
    onFocus: PropTypes.func,
    disablePick: PropTypes.bool,
    changeFieldTransitDoc: PropTypes.func,
    isKaliningradTicket: PropTypes.bool,
  };

  static defaultProps = {
    onFocus: () => {},
    onRemove: () => {},
    onSelect: () => {},
    onChange: () => {},
    valid: '',
    placeholder: '',
    items: null,
    disablePick: false,
    isKaliningradTicket: false,
    changeFieldTransitDoc: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedSuggestion: 0,
      inputValue: '',
    };
  }

  handleOpen = () => {
    document.addEventListener('click', this.handleTouchOutside);
    this.setState({
      open: true,
    });
    this.props.onFocus();
  };

  handleClose = () => {
    document.removeEventListener('click', this.handleTouchOutside);
    this.setState({
      open: false,
      selectedSuggestion: 0,
      inputValue: '',
    });
  };

  handleTouchOutside = (event) => {
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      const { selectedSuggestion } = this.state;
      const { items } = this.props;

      if (items.length && selectedSuggestion) {
        this.handleSelect(items[selectedSuggestion]);
      } else {
        this.handleClose();
      }
    }
  };

  handleChangeInput = ({ target: { value } }) => {
    this.props.onChange(value);
    this.setState({
      selectedSuggestion: 0,
      inputValue: value,
    });
  };

  handleKeyDown = (event) => {
    const { selectedSuggestion } = this.state;
    const { items } = this.props;

    if (!items.length) {
      return;
    }

    switch (event.keyCode) {
      case KEYCODE.TAB:
      case KEYCODE.ENTER:
        event.preventDefault();
        this.handleSelect(items[selectedSuggestion]);
        break;
      case KEYCODE.UP:
        event.preventDefault();
        this.setState({
          selectedSuggestion: selectedSuggestion ? selectedSuggestion - 1 : items.length - 1,
        });
        break;
      case KEYCODE.DOWN:
        event.preventDefault();
        this.setState({
          selectedSuggestion: selectedSuggestion === items.length - 1 ? 0 : selectedSuggestion + 1,
        });
        break;
    }
  };

  handleClickOnSuggestion = (item, event) => {
    this.handleSelect(item);
    event.stopPropagation();
  };

  handleSelect = (item) => {
    this.props.onSelect(item);
    this.handleClose();
  };

  handleRemove(item, event) {
    event.stopPropagation();

    const { onRemove, isKaliningradTicket, changeFieldTransitDoc } = this.props;

    onRemove(item);

    if (isKaliningradTicket) {
      changeFieldTransitDoc(
        TRAIN_TRANSIT_DOCUMENT_TYPE.DEFAULT,
        `${FIELDS.JSON_DATA}.${FIELDS.TRANSIT_DOCUMENT_TYPE}`,
      );
    }
  }

  renderInput() {
    return (
      <input
        // eslint-disable-next-line
        role='combobox'
        value={ this.state.inputValue }
        onChange={ this.handleChangeInput }
        onKeyDown={ this.handleKeyDown }
        onFocus={ this.handleOpen }
      />
    );
  }

  renderSelectedValue = (employees) => {
    const { Surname, Name, Patronymic } = employees;

    return (
      <div className={ styles.value } key={ employees.Id }>
        <div className={ styles.name }>
          { Surname } { Name } { Patronymic }
        </div>
        <div className={ styles.cross } onClick={ e => this.handleRemove(employees, e) }>
          <i className='material-icons'>close</i>
        </div>
      </div>
    );
  }

  renderSuggestion = () => {
    const { open, selectedSuggestion } = this.state;
    const { items } = this.props;

    if (!open) {
      return null;
    }

    return (
      <div className={ styles.list }>
        <div className={ styles.items }>
          { items.length ? items.map((item, index) => {
            const { Surname, Name, Patronymic, Birthday } = item;
            const employeeFullName = `${Surname} ${Name} ${Patronymic} ${formatDate(Birthday)}`;

            return (
              <div
                key={ index }
                className={ `${styles.item} ${selectedSuggestion === index ? styles.active : ''}` }
                onClick={ e => this.handleClickOnSuggestion(item, e) }
              >
                { employeeFullName }
              </div>
            );
          }) : null }
        </div>
      </div>
    );
  };

  render() {
    const { open } = this.state;

    const {
      selected,
      placeholder,
      valid,
      disablePick,
    } = this.props;

    if (disablePick) {
      return (
        <div className={ styles.control }>
          <div className={ styles.wrap }>
            <label>
              { placeholder }
            </label>
            <div className={ styles.absence }>Сначала выберите компанию</div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={ `${styles.control} ${valid ? styles['no-valid'] : ''}` }
        ref={ (ref) => { this.wrapper = ref; } }
      >
        <div className={ `${styles.wrap} ${open ? styles.active : ''}` }>
          { selected.Id ?
            this.renderSelectedValue(selected) :
            this.renderInput()
          }
          <label>
            { placeholder }
          </label>
        </div>
        {this.renderSuggestion()}
        { valid ? <span className={ styles['error-msg'] }>{ valid }</span> : null }
      </div>
    );
  }
}

export default EmployeeSuggest;
