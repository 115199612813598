import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles/index.module.css';

const DRAGGABLE_ITEMS_STYLES = { width: '100%', textAlign: 'center' };

const DraggableItem = ({
  isRed,
  onDragStart,
  onDragEnd,
  onClick,
  text,
  renderContent,
  readonly,
  isStyle,
  qaAttr,
}) => {
  const classNames = [styles.wrapper];

  if (isRed) {
    classNames.push(styles.red);
  }

  if (!readonly && !!onClick) {
    classNames.push(styles.pointer);
  }

  const styleDrag = isStyle ? DRAGGABLE_ITEMS_STYLES : undefined;

  const content = renderContent ? renderContent() : text;

  const handleDragStart = ({ dataTransfer }) => {
    onDragStart();

    // Firefox & Safari dragndrop fix sooqa
    const txt = dataTransfer.getData('text');
    dataTransfer.setData('text', txt);
  };

  return (
    <div
      draggable={ !readonly }
      onDragStart={ handleDragStart }
      onDragEnd={ onDragEnd }
      className={ classNames.join(' ') }
      onClick={ onClick }
      style={ styleDrag }
      data-qa={ qaAttr }
    >
      { content }
    </div>
  );
};

DraggableItem.propTypes = {
  isRed: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
  renderContent: PropTypes.func,
  readonly: PropTypes.bool,
  isStyle: PropTypes.bool,
  qaAttr: PropTypes.string,
};

DraggableItem.defaultProps = {
  isRed: false,
  onDragStart: () => {},
  onDragEnd: () => {},
  onClick: () => {},
  text: '',
  renderContent: null,
  readonly: false,
  isStyle: false,
  qaAttr: '',
};

export { DraggableItem };
