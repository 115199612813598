import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, Select, Tooltip } from 'sw-ui';

import AjaxButton from '../ajaxButton';
import Input from '../input';

import Timer from '../../modules/cartsAndNotes/components/Timer';

import { timerAdder } from '../../bi/utils/formatDate';
import { isEmail } from '../../bi/utils/emailValidator';

import { TIME_SESSION } from '../../bi/constants/clientSession';
import { TRIPBUTTONS } from '../../bi/constants/trips';
import COMPONENTS from '../../bi/constants/components';

import styles from './index.module.css';

const LABELS = {
  HEADER: 'Клиентская сессия Smartway',
  ENTRY: 'Войти',
  EMAIL: 'email',
  EMAIL_RU: 'Введите email клиента',
  TIME_SESSION: 'Время сессии',
  END_SESSION: 'До конца сессии осталось:',
  DISABLED: 'Введите email в требуемом формате',
  ERROR: 'Не удалось найти учетную запись',
  TIME_UP: 'Клиентская сессия завершена',
};

const SECONDS = 'seconds';

const {
  DIALOG: { MIDDLE },
  BUTTON: {
    THEME: { FLAT },
  },
  TOOLTIP: {
    POSITION: {
      BOTTOM,
    },
  },
  SELECT: {
    THEME: {
      BORDER,
    },
  },
} = COMPONENTS;

const { CLOSE } = TRIPBUTTONS;

const WorkplaceClientSession = ({ onSessionStart, resetPassStatus }) => {
  const [email, setEmail] = useState('');
  const [selectedTime, setSelectedTime] = useState(600);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [deadLine, setDeadLine] = useState('');
  const [showError, setShowError] = useState(false);
  const [showDialogTimeUp, setShowDialogTimeUp] = useState(false);

  const handleFilterTime = ({ value }) => {
    setSelectedTime(value);
  };

  const entryClientSession = () => {
    setLoadingButton(true);
    setDeadLine('');
    setShowTimer(false);
    resetPassStatus();

    onSessionStart(email, selectedTime)
    .then(() => {
      const strDeadLine = timerAdder(selectedTime, SECONDS);
      setDeadLine(strDeadLine);
      setShowTimer(true);
    })
    .catch(() => {
      setShowError(true);
    })
    .finally(() => {
      setLoadingButton(false);
    });
  };

  const handleCloseDialogTimeUp = () => {
    setShowDialogTimeUp(false);
    setShowTimer(false);
  };

  const closeTimer = () => {
    setShowDialogTimeUp(true);
  };

  const closeErrorDialog = () => {
    setShowError(false);
  };

  const renderErrorDialog = () => showError ? (
    <Dialog onClick={ closeErrorDialog } width={ MIDDLE }>
      <div className={ styles.dialog }>
        <p>{LABELS.ERROR}</p>
        <div>
          <Button label={ CLOSE } theme={ FLAT } onClick={ closeErrorDialog } />
        </div>
      </div>
    </Dialog>
  ) : null;

  const renderTimeUpDialog = () => showDialogTimeUp ? (
    <Dialog onClick={ handleCloseDialogTimeUp } width={ MIDDLE }>
      <div className={ styles.dialog }>
        <p>{LABELS.TIME_UP}</p>
        <div>
          <Button label={ CLOSE } theme={ FLAT } onClick={ handleCloseDialogTimeUp } />
        </div>
      </div>
    </Dialog>
  ) : null;

  const renderTimer = () => {
    if (!showTimer) {
      return null;
    }

    return (
      <div className={ styles.timer }>
        {LABELS.END_SESSION}
        <Timer
          deadLineInStrUTC={ deadLine }
          onCloseTimer={ closeTimer }
        />
      </div>
    );
  };

  const disabled = !isEmail(email);

  const tooltipHtml = disabled ? (
    <Tooltip position={ BOTTOM }>
      <div className={ styles.tooltip }>{LABELS.DISABLED}</div>
    </Tooltip>
  ) : null;

  return (
    <div className={ styles.content }>
      <div className={ styles.header }>
        { LABELS.HEADER }
      </div>
      <div className={ styles.form }>
        <div className={ `${styles.wrapper_input}` }>
          <Input
            type={ LABELS.EMAIL }
            field={ LABELS.EMAIL }
            placeholder={ LABELS.EMAIL_RU }
            onChange={ event => setEmail(event.target.value) }
            value={ email }
            className={ styles.emptyPadding }
          />
        </div>
        <Select
          label={ LABELS.TIME_SESSION }
          className={ styles.wrapper_block }
          items={ TIME_SESSION }
          value={ selectedTime }
          theme={ BORDER }
          onChange={ value => handleFilterTime(value) }
        />
        <div className={ `${styles.wrapper_block} sw-tooltip-wrapper` }>
          { tooltipHtml }
          <AjaxButton
            label={ LABELS.ENTRY }
            loading={ loadingButton }
            disabled={ disabled }
            onClick={ () => entryClientSession() }
          />
        </div>
        {renderTimer()}
        {renderErrorDialog()}
        {renderTimeUpDialog()}
      </div>
    </div>
  );
};

WorkplaceClientSession.propTypes = {
  onSessionStart: PropTypes.func.isRequired,
  resetPassStatus: PropTypes.func.isRequired,
};

export { WorkplaceClientSession };
