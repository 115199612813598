import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'sw-ui';

import HTTPCODES from '../../constants/http';
import { DECISIONBUTTONS } from '../../bi/constants/decisionButtons';
import COMPONENTS from '../../bi/constants/components';
import MESSAGE from '../../bi/constants/noResult';

import AjaxButton from '../ajaxButton';
import NoResult from '../NoResult';

import styles from './styles/index.module.css';

class DecisionButtons extends Component {
  static propTypes = {
    loadingIncluded: PropTypes.bool,
    disabled: PropTypes.bool,
    waitingResponse: PropTypes.bool,
    showTooltip: PropTypes.bool,
    tooltip: PropTypes.string,
    labelSave: PropTypes.string,
    labelCancel: PropTypes.string,
    tooltipPosition: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    showCancel: PropTypes.bool,
    showSave: PropTypes.bool,
    closeAfterSuccess: PropTypes.bool,
  };

  static defaultProps = {
    loadingIncluded: false,
    disabled: false,
    waitingResponse: false,
    showTooltip: false,
    tooltip: '',
    showCancel: true,
    showSave: true,
    tooltipPosition: COMPONENTS.TOOLTIP.POSITION.TOP,
    labelSave: DECISIONBUTTONS.LABELS.SAVE,
    labelCancel: DECISIONBUTTONS.LABELS.CANCEL,
    onCancel: () => {},
    onSave: () => {},
    closeAfterSuccess: true,
  };

  state = {
    loading: false,
    errorMsg: '',
  };

  toggleLoading = () => this.setState({
    loading: !this.state.loading,
  });

  toggleErrorDialog = (msg = '') => this.setState({
    errorMsg: msg,
  }, () => {
    if (!this.state.errorMsg.length) this.props.onCancel();
  });

  handleSave = () => {
    const {
      loadingIncluded,
      onSave,
      onCancel,
      closeAfterSuccess,
    } = this.props;

    if (!loadingIncluded) {
      return onSave();
    }

    this.toggleLoading();

    return onSave()
      .then(() => {
        this.toggleLoading();

        return closeAfterSuccess ? onCancel() : () => {};
      })
      .catch((e) => {
        const msg = e === HTTPCODES.NOTFOUND ? MESSAGE.DEFAULT : MESSAGE.RESPONSE;

        this.toggleLoading();
        this.toggleErrorDialog(msg);
      });
  };

  renderTooltip = () => {
    const { tooltipPosition, tooltip } = this.props;
    return (
      <Tooltip position={ tooltipPosition }>
        { tooltip }
      </Tooltip>
    );
  };

  renderErrorDialog = () => (
    <NoResult message={ this.state.errorMsg } onClose={ this.toggleErrorDialog } />
  );

  renderSaveButton = () => {
    const {
      showSave,
      disabled,
      labelSave,
      waitingResponse,
      tooltip,
      showTooltip,
    } = this.props;
    const { loading } = this.state;

    const tooltipHtml = tooltip && (showTooltip || disabled) && this.renderTooltip();

    return showSave && (
      <div className='sw-tooltip-wrapper'>
        <AjaxButton
          disabled={ disabled }
          label={ labelSave }
          loading={ waitingResponse || loading }
          onClick={ this.handleSave }
        />
        { tooltipHtml }
      </div>
    );
  }

  renderCloseButton = () => {
    const {
      onCancel,
      labelCancel,
      showCancel,
    } = this.props;

    return showCancel && (
      <div className='sw-tooltip-wrapper'>
        <Button
          label={ labelCancel }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ onCancel }
        />
      </div>
    );
  }

  render() {
    const { errorMsg } = this.state;

    const errorDialogHtml = !!errorMsg.length && this.renderErrorDialog();

    return (
      <div className={ styles.row }>
        { this.renderCloseButton() }
        { this.renderSaveButton() }
        { errorDialogHtml }
      </div>
    );
  }
}

export default DecisionButtons;
